function getZoneApi() {
    let data = {'id': idClicked}
    postDataPut('/get-zones/', data)
        .then(data => {
            document.getElementById('zone_id').innerHTML = ""
            document.getElementById('zone_id').append(document.createElement('option'))
            data.forEach(function (element) {
                let option = document.createElement('option')
                option.value = element.id
                option.innerHTML = element.name
                document.getElementById('zone_id').append(option)
            })
        })
        .catch(function (error) {
            console.log(error);
        })
}

if (typeof inputCity !== 'undefined' && inputCity !== 0) {
    inputCity.addEventListener('click', getZoneApi)
}