require("./bootstrap");
require("alpinejs");
require("./property-zone.js");
require("./user.js");
require('./thousands.js');
//require('@fortawesome/fontawesome-free/js/all.min.js');
import {Fancybox} from "@fancyapps/ui";

/*
 * Js per le tab delle vetrine in property/tabs_edit/poster.blade.php
 */
document.addEventListener('DOMContentLoaded', () => {
    // Trova tutti i contenitori di tab
    const tabContainers = document.querySelectorAll('.tabs-container');

    tabContainers.forEach(container => {
        const selectTab = container.querySelector('select[aria-label="Select a tab"]');
        const navLinks = container.querySelectorAll('nav a');
        const contents = container.querySelectorAll('[data-tab-content]');

        function setActiveTab(tab) {
            // Cambia la selezione nella vista mobile
            if (selectTab) selectTab.value = tab;

            // Cambia lo stato dei link nella vista desktop
            navLinks.forEach(link => {
                link.classList.remove('bg-gray-200', 'text-gray-800');
                link.classList.add('text-gray-600', 'hover:text-gray-800');
                if (link.getAttribute('data-tab') === tab) {
                    link.classList.add('bg-gray-200', 'text-gray-800');
                    link.classList.remove('text-gray-600');
                }
            });

            // Mostra/nascondi i contenuti dei tab
            contents.forEach(content => {
                content.classList.add('hidden');
                if (content.getAttribute('data-tab-content') === tab) {
                    content.classList.remove('hidden');
                }
            });
        }

        // Gestione del cambio nella vista mobile
        if (selectTab) {
            selectTab.addEventListener('change', () => {
                setActiveTab(selectTab.value);
            });
        }

        // Gestione del click nei link della vista desktop
        navLinks.forEach(link => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                setActiveTab(link.getAttribute('data-tab'));
            });
        });
    });
});