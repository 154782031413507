if (document.getElementById('priceTo') !== null && document.getElementById('priceFrom') !== null) {
    document.getElementById('priceTo').value = ControlloNumero(document.getElementById('priceTo'))
    document.getElementById('priceFrom').value = ControlloNumero(document.getElementById('priceFrom'))

    document.getElementById('priceTo').addEventListener('keyup', function () {
        document.getElementById('priceTo').value = ControlloNumero(document.getElementById('priceTo'))
    })
    document.getElementById('priceFrom').addEventListener('keyup', function () {
        document.getElementById('priceFrom').value = ControlloNumero(document.getElementById('priceFrom'))
    })

}
if (document.getElementById('mqTo') !== null && document.getElementById('mqFrom') !== null) {
    document.getElementById('mqTo').value = ControlloNumero(document.getElementById('mqTo'))
    document.getElementById('mqFrom').value = ControlloNumero(document.getElementById('mqFrom'))
    document.getElementById('mqTo').addEventListener('keyup', function () {
        document.getElementById('mqTo').value = ControlloNumero(document.getElementById('mqTo'))
    })
    document.getElementById('mqFrom').addEventListener('keyup', function () {
        document.getElementById('mqFrom').value = ControlloNumero(document.getElementById('mqFrom'))
    })
}

if (document.getElementById('price_to') !== null) {
    document.getElementById('price_to').value = ControlloNumero(document.getElementById('price_to'))
    document.getElementById('price_from').value = ControlloNumero(document.getElementById('price_from'))
    document.getElementById('price_to').addEventListener('keyup', function () {
        document.getElementById('price_to').value = ControlloNumero(document.getElementById('price_to'))
    })
    document.getElementById('price_from').addEventListener('keyup', function () {
        document.getElementById('price_from').value = ControlloNumero(document.getElementById('price_from'))
    })
    document.getElementById('price_to').value = ControlloNumero(document.getElementById('price_to'))
    document.getElementById('price_from').value = ControlloNumero(document.getElementById('price_from'))
}

if (document.getElementById('prezzo_da') !== null) {
    document.getElementById('prezzo_da').value = ControlloNumero(document.getElementById('prezzo_da'))
    document.getElementById('prezzo_a').value = ControlloNumero(document.getElementById('prezzo_a'))
    document.getElementById('prezzo_da').addEventListener('keyup', function () {
        document.getElementById('prezzo_da').value = ControlloNumero(document.getElementById('prezzo_da'))
    })
    document.getElementById('prezzo_a').addEventListener('keyup', function () {
        document.getElementById('prezzo_a').value = ControlloNumero(document.getElementById('prezzo_a'))
    })
}

function ControlloNumero(obj) {
    let numero = "positivo";
    if (obj.value.substr(0, 1) === "-") numero = "negativo";
    let valore = obj.value.replace(/[^\d]/g, '').replace(/^0+/g, '');
    let nuovovalore = '';
    while (valore.length > 3) {
        nuovovalore = '.' + valore.substr(valore.length - 3) + nuovovalore
        valore = valore.substr(0, valore.length - 3)
    }
    if (numero === "negativo") obj.value = "-" + valore + nuovovalore
    else obj.value = valore + nuovovalore
    return obj.value
}