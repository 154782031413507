if (document.getElementById('priceTo') !== null && document.getElementById('priceFrom') !== null) {
    document.getElementById('priceTo').value = ControlloNumero(document.getElementById('priceTo'))
    document.getElementById('priceFrom').value = ControlloNumero(document.getElementById('priceFrom'))

    document.getElementById('priceTo').addEventListener('keyup', function () {
        document.getElementById('priceTo').value = ControlloNumero(document.getElementById('priceTo'))
    })
    document.getElementById('priceFrom').addEventListener('keyup', function () {
        document.getElementById('priceFrom').value = ControlloNumero(document.getElementById('priceFrom'))
    })

}
if (document.getElementById('mqTo') !== null && document.getElementById('mqFrom') !== null) {
    document.getElementById('mqTo').value = ControlloNumero(document.getElementById('mqTo'))
    document.getElementById('mqFrom').value = ControlloNumero(document.getElementById('mqFrom'))
    document.getElementById('mqTo').addEventListener('keyup', function () {
        document.getElementById('mqTo').value = ControlloNumero(document.getElementById('mqTo'))
    })
    document.getElementById('mqFrom').addEventListener('keyup', function () {
        document.getElementById('mqFrom').value = ControlloNumero(document.getElementById('mqFrom'))
    })
}

if (document.getElementById('price_to') !== null) {
    document.getElementById('price_to').value = ControlloNumero(document.getElementById('price_to'))
    document.getElementById('price_from').value = ControlloNumero(document.getElementById('price_from'))
    document.getElementById('price_to').addEventListener('keyup', function () {
        document.getElementById('price_to').value = ControlloNumero(document.getElementById('price_to'))
    })
    document.getElementById('price_from').addEventListener('keyup', function () {
        document.getElementById('price_from').value = ControlloNumero(document.getElementById('price_from'))
    })
    document.getElementById('price_to').value = ControlloNumero(document.getElementById('price_to'))
    document.getElementById('price_from').value = ControlloNumero(document.getElementById('price_from'))
}

if (document.getElementById('prezzo_da') !== null) {
    document.getElementById('prezzo_da').value = ControlloNumero(document.getElementById('prezzo_da'))
    document.getElementById('prezzo_a').value = ControlloNumero(document.getElementById('prezzo_a'))
    document.getElementById('prezzo_da').addEventListener('keyup', function () {
        document.getElementById('prezzo_da').value = ControlloNumero(document.getElementById('prezzo_da'))
    })
    document.getElementById('prezzo_a').addEventListener('keyup', function () {
        document.getElementById('prezzo_a').value = ControlloNumero(document.getElementById('prezzo_a'))
    })
}

/**
 *
 * @param obj
 * @returns {string|string}
 * @constructor
 */
function ControlloNumero(obj) {
    // Verifica che l'oggetto esista e abbia una proprietà 'value' valida
    if (!obj || typeof obj.value !== "string") {
        console.error("Errore: l'oggetto passato deve avere una proprietà 'value' di tipo stringa.");
        return '';
    }

    // Determina se il numero è negativo
    const isNegative = obj.value.trim().startsWith("-");

    // Rimuove tutto ciò che non è cifra
    let valore = obj.value.replace(/[^\d]/g, '').replace(/^0+/g, '');

    // Formatta il numero con separatori di migliaia
    const valoreFormattato = valore.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // Aggiunge il segno negativo se necessario
    obj.value = isNegative && valoreFormattato ? `-${valoreFormattato}` : valoreFormattato;

    return obj.value;
}
